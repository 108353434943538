<script>

import { reactive, ref } from 'vue'
import { userService } from '../../../helpers/authservice/user.service'
import Swal from "sweetalert2"
import { useRoute } from 'vue-router'
import router from '../../../router'

export default {
  components: {
  },
  setup() {
    const route = useRoute()
    const { redirectFrom } = route.query
    const form = reactive({
      email: '',
      sistema: '281486ff-c347-cd3c-7e43-1756d13ebf4d',
      // rememberme: localStorage.getItem('rememberme') === 'true' ? true : false,
    })

    const loading = ref(false)

    async function handleLoginUser() {
      if (loading.value) return false;

      loading.value = true
      const error = await userService.login({ ...form }, redirectFrom, true)
      console.log(error)
      if(error == 'FIRST_ACCESS'){
        router.replace({ name: 'signin-basic', query: { redirectFrom, stage: 4, email: form.email, sistema: form.sistema } })
      }else if(error == 'INVALID_PASSWORD'){
        router.replace({ name: 'signin-basic', query: { redirectFrom, email: form.email, sistema: form.sistema } })
      }else if(!error.token) {
        Swal.fire({
          icon: "error",
          text: error,
          html: error,
          confirmButtonColor: "#34c38f",
          confirmButtonText: "OK",
        })
      }
      loading.value = false
    }

    const redirectToLogin = () => {
      router.replace({ name: 'signin-basic', query: { redirectFrom } });
    }

    return {
      form,
      handleLoginUser,
      loading,
      redirectToLogin
    }
  },
}
</script>

<template>
  <div class="auth-page-wrapper pt-1">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z" />
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logos/logoAzul.png" alt="" height="100">
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8 col-lg-6 col-xl-5 mx-auto">
            <div class="card mt-4">

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">
                    Primeiro acesso!
                  </h5>
                  <div class="text-muted text-center mb-4 mx-lg-3">
                    <p>Escolha um email para continuar.</p>
                  </div>
                </div>

                <div class="px-2 mt-4 d-flex flex-column">
                  <label>Tipo de acesso</label>
                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" checked class="btn-check" name="sistema" value="281486ff-c347-cd3c-7e43-1756d13ebf4d" v-model="form.sistema" id="colaborador-check">
                    <label class="btn btn-outline-primary" for="colaborador-check">Colaborador</label>
                    <input type="radio" class="btn-check" name="sistema" value="f3d11f0c-352d-43c9-ba53-a4a2149df719" v-model="form.sistema" id="fornecedor-check">
                    <label class="btn btn-outline-primary" for="fornecedor-check">Fornecedor</label>
                    <input type="radio" class="btn-check" name="sistema" value="3b416297-880d-4ed5-8ca0-0a3020681057" v-model="form.sistema" id="cliente-check">
                    <label class="btn btn-outline-primary" for="cliente-check">Cliente</label>
                  </div>
                </div>

                <div class="p-2 mt-1">
                  <form @submit.prevent="handleLoginUser">

                    <div class="mb-3">
                      <label for="username" class="form-label">
                        Email
                      </label>
                      <input v-model="form.email" type="text" class="form-control" id="username"
                        placeholder="Coloque seu Email">
                    </div>

                    <div v-if="loading" class="mt-3 d-flex justify-content-center">
                      <b-spinner variant="success"></b-spinner>
                    </div>
                    <div v-if="!loading" class="mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Continuar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">
                Já tem uma conta?
                <button @click="redirectToLogin" type="button" class="btn fw-semibold text-primary text-decoration-underline px-1 py-0">
                  Entrar
                </button>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                Copyright &copy; {{ new Date().getFullYear() }} Rede São Roque - Apoio
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>